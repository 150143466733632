'use client'

import { useEffect } from 'react';

export const AppConfig = () => {
    useEffect(() => {
        console.log('AppVersion', process.env.version);
    }, []);

    return null;
};
